import axios, {
    AxiosError,
    AxiosInstance,
    AxiosRequestConfig,
    AxiosResponse,
} from 'axios'
// eslint-disable-next-line @nx/enforce-module-boundaries
import type { LoginResponse } from '@podroof/mole-services/auth'
import handleAxiosError from './handleAxiosError'
import refreshTokenErrorResponseInterceptor from './refreshTokenErrorResponseInterceptor'

// copied from umijs plugin-request
// TODO switch to got or something

interface IRequestOptions extends AxiosRequestConfig {
    skipErrorHandler?: boolean
    requestInterceptors?: IRequestInterceptorTuple[]
    responseInterceptors?: IResponseInterceptorTuple[]
    [key: string]: any
}

interface IRequestOptionsWithResponse extends IRequestOptions {
    getResponse: true
}

interface IRequestOptionsWithoutResponse extends IRequestOptions {
    getResponse: false
}

interface IRequest {
    <T = any>(url: string, opts: IRequestOptionsWithResponse): Promise<
        AxiosResponse<T>
    >
    <T = any>(url: string, opts: IRequestOptionsWithoutResponse): Promise<T>
    <T = any>(url: string, opts: IRequestOptions): Promise<T> // getResponse 默认是 false， 因此不提供该参数时，只返回 data
    <T = any>(url: string): Promise<T> // 不提供 opts 时，默认使用 'GET' method，并且默认返回 data
}

type RequestError = AxiosError | Error

interface IErrorHandler {
    (error: RequestError, opts: IRequestOptions): void
}
type WithPromise<T> = T | Promise<T>
type IRequestInterceptorAxios = (
    config: IRequestOptions
) => WithPromise<IRequestOptions>
type IRequestInterceptorUmiRequest = (
    url: string,
    config: IRequestOptions
) => WithPromise<{ url: string; options: IRequestOptions }>
type IRequestInterceptor =
    | IRequestInterceptorAxios
    | IRequestInterceptorUmiRequest
type IErrorInterceptor = (error: Error) => Promise<Error>
type IResponseInterceptor = <T = any>(
    response: AxiosResponse<T>
) => WithPromise<AxiosResponse<T>>
type IRequestInterceptorTuple =
    | [IRequestInterceptor, IErrorInterceptor]
    | [IRequestInterceptor]
    | IRequestInterceptor
type IResponseInterceptorTuple =
    | [IResponseInterceptor, IErrorInterceptor]
    | [IResponseInterceptor]
    | IResponseInterceptor

export interface RequestConfig<T = any> extends AxiosRequestConfig {
    errorConfig?: {
        errorHandler?: IErrorHandler
        errorThrower?: (res: T) => void
    }
    requestInterceptors?: IRequestInterceptorTuple[]
    responseInterceptors?: IResponseInterceptorTuple[]
}

const defaultConfig: RequestConfig = {
    baseURL: process.env.SERVICES_BASEURL,
    timeout: process.env.NODE_ENV === 'production' ? 20000 : 0,
    // other axios options you want
    errorConfig: {
        errorHandler(error) {
            handleAxiosError(error)
        },
        errorThrower(error) {
            throw error
        },
    },
    requestInterceptors: [
        (url, options) => {
            // TODO only for our backend

            // FIXME too much uncertainty, but need a better solution
            // just disable backend cache now and rely on react-query until we sort out a solution
            const params = options.params || {}
            if (typeof params.noCache === 'undefined') {
                params.noCache = true
            }

            const authString = localStorage.getItem('auth')
            const auth = authString
                ? (JSON.parse(authString) as LoginResponse)
                : undefined

            console.log('req', url, params)

            const idToken = auth?.tokens?.id_token

            if (!idToken) {
                return { url, options: { ...options, params } }
            }

            return {
                url,
                options: {
                    ...options,
                    params,
                    headers: {
                        ...options.headers,
                        Authorization: `Bearer ${idToken}`,
                    },
                },
            }
        },
    ],
    responseInterceptors: [
        // @ts-expect-error IErrorInterceptor ResponseType incorrectly Promise<Error>. Should just be Promise. can resolve a success or reject with error
        [async (response) => response, refreshTokenErrorResponseInterceptor],
    ],
    headers: {
        // FIXME hack admin bearer
        Authorization: 'Bearer 2MzgAJd8WqBHe03sJzfU2j0z7p7yjYeq',
    },
}

let requestInstance: AxiosInstance
let config: RequestConfig
const getConfig = (): RequestConfig => {
    if (config) return config
    config = defaultConfig

    return config
}

export const getRequestInstance = (): AxiosInstance => {
    if (requestInstance) return requestInstance
    const config = getConfig()
    requestInstance = axios.create(config)

    config?.requestInterceptors?.forEach((interceptor) => {
        if (interceptor instanceof Array) {
            requestInstance.interceptors.request.use(async (config) => {
                const { url } = config
                if (interceptor[0].length === 2) {
                    const { url: newUrl, options } = await interceptor[0](
                        // @ts-expect-error typing
                        url,
                        config
                    )
                    return { ...options, url: newUrl }
                }
                // @ts-expect-error args
                return interceptor[0](config)
            }, interceptor[1])
        } else {
            requestInstance.interceptors.request.use(async (config) => {
                const { url } = config
                if (interceptor.length === 2) {
                    const { url: newUrl, options } = await interceptor(
                        // @ts-expect-error typing
                        url,
                        config
                    )
                    return { ...options, url: newUrl }
                }
                // @ts-expect-error args
                return interceptor(config)
            })
        }
    })

    config?.responseInterceptors?.forEach((interceptor) => {
        interceptor instanceof Array
            ? requestInstance.interceptors.response.use(
                  interceptor[0],
                  interceptor[1]
              )
            : requestInstance.interceptors.response.use(interceptor)
    })

    // 当响应的数据 success 是 false 的时候，抛出 error 以供 errorHandler 处理。
    requestInstance.interceptors.response.use((response) => {
        const { data } = response
        if (data?.success === false && config?.errorConfig?.errorThrower) {
            config.errorConfig.errorThrower(data)
        }
        return response
    })

    return requestInstance
}

// @ts-expect-error typing
const request: IRequest = (
    url: string,
    opts: IRequestOptions = { method: 'GET' }
) => {
    const requestInstance = getRequestInstance()
    const config = getConfig()
    const {
        getResponse = false,
        requestInterceptors,
        responseInterceptors,
    } = opts

    const requestInterceptorsToEject = requestInterceptors?.map(
        (interceptor) => {
            if (interceptor instanceof Array) {
                return requestInstance.interceptors.request.use(
                    async (config) => {
                        const { url } = config
                        if (interceptor[0].length === 2) {
                            const { url: newUrl, options } =
                                // @ts-expect-error typing
                                await interceptor[0](url, config)
                            return { ...options, url: newUrl }
                        }
                        // @ts-expect-error args
                        return interceptor[0](config)
                    },
                    interceptor[1]
                )
            } else {
                return requestInstance.interceptors.request.use(
                    async (config) => {
                        const { url } = config
                        if (interceptor.length === 2) {
                            const { url: newUrl, options } = await interceptor(
                                //@ts-expect-error typing
                                url,
                                config
                            )
                            return { ...options, url: newUrl }
                        }
                        // @ts-expect-error arg count
                        return interceptor(config)
                    }
                )
            }
        }
    )
    const responseInterceptorsToEject = responseInterceptors?.map(
        (interceptor) => {
            return interceptor instanceof Array
                ? requestInstance.interceptors.response.use(
                      interceptor[0],
                      interceptor[1]
                  )
                : requestInstance.interceptors.response.use(interceptor)
        }
    )
    return new Promise((resolve, reject) => {
        requestInstance
            .request({ ...opts, url })
            .then((res) => {
                requestInterceptorsToEject?.forEach((interceptor) => {
                    requestInstance.interceptors.request.eject(interceptor)
                })
                responseInterceptorsToEject?.forEach((interceptor) => {
                    requestInstance.interceptors.response.eject(interceptor)
                })
                resolve(getResponse ? res : res.data)
            })
            .catch((error) => {
                requestInterceptorsToEject?.forEach((interceptor) => {
                    requestInstance.interceptors.request.eject(interceptor)
                })
                responseInterceptorsToEject?.forEach((interceptor) => {
                    requestInstance.interceptors.response.eject(interceptor)
                })
                try {
                    const handler = config?.errorConfig?.errorHandler
                    // @ts-expect-error arg count
                    if (handler) handler(error, opts, config)
                } catch (e) {
                    reject(e)
                }
                reject(error)
            })
    })
}

export default request
