import { useIsFetching } from '@tanstack/react-query';
import { Spin } from 'antd';

export default () => {
    const isFetching = useIsFetching();

    return (
        <Spin
            size="large"
            spinning={isFetching > 0}
            // delay={50}
        />
    );
};
