// Runtime configuration
import GlobalSpinner from '@/components/GlobalSpinner';
import {
    handleAxiosError,
    refreshTokenErrorResponseInterceptor,
} from '@podroof/web-request';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { QueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import {
    history,
    type RequestConfig,
    type RuntimeConfig,
    type RunTimeLayoutConfig,
} from '@umijs/max';
import { Empty, Flex, FloatButton, Typography } from 'antd';
import assert from 'assert';
import axios from 'axios';

// TODO switch to use defineApp https://umijs.org/en-US/docs/api/runtime-config#typescript-hints

// Global initialization data configuration, used for Layout user information and permission initialization
// See the documentation for more information: https://umijs.org/docs/api/runtime-config#getinitialstate
import type { LoginResponse } from '@podroof/mole-services/auth';
import type React from 'react';

export type InitialState = {
    auth?: LoginResponse;
    loading?: boolean;
    message?: string;
};

export async function getInitialState(): Promise<InitialState> {
    const authString = localStorage.getItem('auth');
    const auth = authString
        ? (JSON.parse(authString) as LoginResponse)
        : undefined;

    const state: InitialState = {
        auth,
        loading: false,
    };

    // Check if token is expired
    //  Let backend do it
    // TODO or maybe just do a fast re-login on the front-end instead?
    /*
    if (auth?.tokens?.expiry_date && Date.now() > auth.tokens.expiry_date) {
        // Token is expired. Clear the auth
        localStorage.removeItem('auth');

        state.auth = undefined;
        state.message = 'Login session has expired. Please login again.';
    }
*/

    return state;
}

export const layout: RuntimeConfig['layout'] = (
    initData,
): ReturnType<RunTimeLayoutConfig> => {
    const { initialState } = initData;

    return {
        title: '',
        logo: (
            <div
                style={{
                    backgroundColor: '#001b39',
                    padding: '10px',
                }}
            >
                <img
                    src="https://podroof.com/_next/image?url=%2F_next%2Fstatic%2Fmedia%2FLogo%402x.0ff05a50.png&w=384&q=75"
                    style={{ maxWidth: '100%' }}
                />
            </div>
        ),
        menu: {
            locale: false,
        },

        onPageChange: (location) => {
            // can't access new initialState here, use localstorage
            const authString = localStorage.getItem('auth');
            const auth = authString
                ? (JSON.parse(authString) as LoginResponse)
                : undefined;

            if (!auth && location?.pathname !== '/login') {
                console.log('No Auth. Redirecting to login ... ');

                history.push('/login');
            }
        },

        logout: () => {
            localStorage.removeItem('auth');
            history.push('/login');
        },

        // TODO ErrorBoundary
        // ErrorBoundary: (props) => props.children,

        // TODO username, avatar, logout-related components (see: src/.umi/plugin-layout/rightRender.tsx)
        rightRender: (initialState, setInitialState, runtimeConfig) => {
            return initialState?.auth ? (
                <Flex vertical>
                    <Typography.Title level={5}>
                        {initialState?.auth.user.name}
                    </Typography.Title>
                    <Typography.Text code>
                        {JSON.stringify(
                            initialState?.auth.user.access,
                            null,
                            1,
                        )}
                    </Typography.Text>
                </Flex>
            ) : (
                <Empty description="" />
            );
        },

        childrenRender: (dom, props) => {
            return (
                <>
                    <div
                        style={{
                            position: 'absolute',
                            right: 40,
                            top: 20,
                        }}
                    >
                        <GlobalSpinner />
                    </div>
                    {dom}
                    <FloatButton.Group>
                        <FloatButton.BackTop type="primary" />
                    </FloatButton.Group>
                </>
            );
        },
    };
};

// TODO some sort of cache handling, maybe retry with nocache?
// FIXME not using libs/web/request?
export const request: RequestConfig = {
    baseURL: process.env.SERVICES_BASEURL,
    timeout: process.env.NODE_ENV === 'production' ? 20000 : 0,
    // other axios options you want
    errorConfig: {
        errorHandler(error) {
            handleAxiosError(error);
        },
        errorThrower(error) {
            throw error;
        },
    },
    requestInterceptors: [
        (url, options) => {
            // TODO only for our backend

            // FIXME too much uncertainty, but need a better solution
            // just disable backend cache now and rely on react-query until we sort out a solution
            const params = options.params || {};
            if (typeof params.noCache === 'undefined') {
                params.noCache = true;
            }

            const authString = localStorage.getItem('auth');
            const auth = authString
                ? (JSON.parse(authString) as LoginResponse)
                : undefined;

            console.log('req', url, params);

            const idToken = auth?.tokens?.id_token;

            if (!idToken) {
                return { url, options: { ...options, params } };
            }

            return {
                url,
                options: {
                    ...options,
                    params,
                    headers: {
                        ...options.headers,
                        Authorization: `Bearer ${idToken}`,
                    },
                },
            };
        },
    ],
    responseInterceptors: [
        // @ts-expect-error IErrorInterceptor ResponseType incorrectly Promise<Error>. Should just be Promise. can resolve a success or reject with error
        [async (response) => response, refreshTokenErrorResponseInterceptor],
    ],
    headers: {
        // Authorization: 'Bearer 2MzgAJd8WqBHe03sJzfU2j0z7p7yjYeq',
    },
};

export const rootContainer: RuntimeConfig['rootContainer'] = (
    lastRootContainer: React.JSX.Element,
    // args: unknown,
) => {
    assert(process.env.GOOGLE_CLIENT_ID, 'GOOGLE_CLIENT_ID is required');
    assert(process.env.SERVICES_BASEURL, 'SERVICES_BASEURL is required');
    assert(process.env.GOOGLE_MAPS_API_KEY, 'GOOGLE_MAPS_API_KEY is required');

    const persister = createSyncStoragePersister({
        storage: window.localStorage,
    });

    // Create a new client with persistence
    const client = new QueryClient({
        defaultOptions: {
            queries: {
                staleTime: 1000 * 60 * 60 * 24, // 1 hour
                retry: false,
                refetchOnWindowFocus: true,
                gcTime: 1000 * 60 * 60 * 24, // 24 hours to match PersistQueryClientProvider maxAge default
            },
        },
    });

    return (
        <GoogleOAuthProvider clientId={process.env.GOOGLE_CLIENT_ID}>
            <PersistQueryClientProvider
                client={client}
                persistOptions={{
                    persister,
                }}
            >
                {lastRootContainer}
                <ReactQueryDevtools initialIsOpen={false} />
            </PersistQueryClientProvider>
        </GoogleOAuthProvider>
    );
};

// TODO login screen
/*
export function render(oldRender) {
  fetch('/api/auth').then(auth => {
    if (auth.isLogin) { oldRender() }
    else {
      location.href = '/login';
      oldRender()
    }
  });
}*/

const customRetry = (failureCount: number, error: Error) => {
    if (axios.isAxiosError(error) && error.response?.status === 500) {
        return false; // Disable retry for 500 errors
    }

    return failureCount < 3; // Default retry behavior (retry up to 3 times)
};

// FIXME remove as we implement our own persisted
// export const reactQuery: RuntimeReactQueryType = {
//     devtool: {},
//     queryClient: {
//         defaultOptions: {
//             queries: {
//                 staleTime: 5 * 60 * 1000,
//                 retry: false,
//                 refetchOnWindowFocus: true,
//                 gcTime: 1000 * 60 * 60 * 24, // 24 hours
//             },
//         },
//     },
// };
