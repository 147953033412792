import type { AxiosError } from 'axios'

const handleAxiosError = (error: Error) => {
    if (error.name === 'AxiosError') {
        const axiosError = error as AxiosError

        if (axiosError.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.error('AxiosResponseError', axiosError.response.data)
            // console.error(axiosError.response.headers);
        } else if (axiosError.request) {
            // The request was made but no response was received
            console.error('AxiosRequestError', axiosError.request)
        } else {
            // Something happened in setting up the request that triggered an Error
            console.error('AxiosError', axiosError)
        }
    } else {
        console.error('Error', error)
    }
}

export default handleAxiosError
