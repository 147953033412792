// eslint-disable-next-line @nx/enforce-module-boundaries
import type { LoginResponse } from '@podroof/mole-services/auth'
import axios, { AxiosError } from 'axios'

export default async (error: AxiosError) => {
    const { response } = error

    if (response) {
        // If the response indicates that the token is expired
        if (response.status === 401) {
            if (
                // @ts-expect-error TODO type guards for data.message
                (response?.data?.message || '').startsWith('token expired')
            ) {
                const authString = localStorage.getItem('auth')
                const auth = authString
                    ? (JSON.parse(authString) as LoginResponse)
                    : undefined

                if (auth?.tokens?.refresh_token) {
                    const {
                        data: { user, tokens },
                    } = await axios.post(
                        `${process.env.SERVICES_BASEURL}/auth/refresh`,
                        {
                            refreshToken: auth.tokens.refresh_token,
                        }
                    )

                    // Update the tokens in local storage
                    console.log('refreshed tokens to local storage', tokens)

                    localStorage.setItem(
                        'auth',
                        JSON.stringify({ user, tokens })
                    )

                    // Retry the original request with the new token
                    const originalRequest = response.config
                    originalRequest.headers.Authorization = `Bearer ${tokens.id_token}`

                    return axios(originalRequest)
                }
            }

            console.log(
                // @ts-expect-error TODO type guards for data.message
                `auth error: ${response?.data?.message || error.message}`
            )

            // unhandled. redirect to login
            localStorage.removeItem('auth')
            // state.auth = undefined;

            console.log('redirecting to login')
            // history.push('/login');
            window.location.href = '/login'
        }
    }

    throw error
}
