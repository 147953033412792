import { useState } from 'react';

// const lysaghtColorbondColors: Record<string, { name: string; color: string }> =
export const lysaghtColorbondColors = {
    zinc: { name: 'Zincalume ®', color: '#aaa' },
    basalt: { name: 'Basalt®', color: '#4B5056' },
    cove: { name: 'Cove®', color: '#7E7164' },
    classicCream: { name: 'Classic Cream™', color: '#F5E8B1' },
    cottageGreen: { name: 'Cottage Green®', color: '#2D3A33' },
    deepOcean: { name: 'Deep Ocean®', color: '#212C45' },
    dune: { name: 'Dune®', color: '#A28F79' },
    eveningHaze: { name: 'Evening Haze®', color: '#C8BDB1' },
    gully: { name: 'Gully®', color: '#7E7B65' },
    ironstone: { name: 'Ironstone®', color: '#464B4F' },
    jasper: { name: 'Jasper®', color: '#766D5E' },
    mangrove: { name: 'Mangrove®', color: '#4D4F48' },
    manorRed: { name: 'Manor Red®', color: '#5B3A3A' },
    monument: { name: 'Monument®', color: '#3C3C3B' },
    nightSky: { name: 'Night Sky®', color: '#27292A' },
    paleEucalypt: { name: 'Pale Eucalypt®', color: '#68825B' },
    paperbark: { name: 'Paperbark®', color: '#DFD1B6' },
    shaleGrey: { name: 'Shale Grey™', color: '#B7B1A8' },
    surfmist: { name: 'Surfmist®', color: '#E4E4DF' },
    terrain: { name: 'Terrain®', color: '#714B44' },
    wallaby: { name: 'Wallaby®', color: '#746B63' },
    windspray: { name: 'Windspray®', color: '#7A7B75' },
    woodlandGrey: { name: 'Woodland Grey®', color: '#505B57' },
};

interface Building {
    supplyState: string;
    roofStyle: number;
    spanBetweenContainers: number;
    sideBays: string;
    regionIdx: string;
    terrainIdx: string;
    roofColourName: keyof typeof lysaghtColorbondColors;
    bargeColourName: string;
    gableEave: string;
    gutterEave: string;
    spacersRequired: string;
    hiCube: string;
    enclosedRear: string;
    leftExtension: string;
    rightExtension: string;
    forceBarge: string;
    innerOuterIdx: string;
    importanceIndex: string;
    otherFactor: string;
    snowLoad: string;
    soilBearingCapacity: string;
    engineeringRequested: string;
    stubs: string;
}

const defaultBuilding: Building = {
    // loggedUser: 'phil@wattagan.com',
    // country: 'AU',
    supplyState: 'VIC',
    roofStyle: 1,
    spanBetweenContainers: 6,
    sideBays: '1',
    regionIdx: '1',
    terrainIdx: '2',
    roofColourName: 'zinc',
    bargeColourName: 'zinc',
    gableEave: '0',
    gutterEave: '0',
    spacersRequired: '0',
    hiCube: '0',
    enclosedRear: '0',
    leftExtension: '1',
    rightExtension: '1',
    forceBarge: '0',
    innerOuterIdx: '2',
    importanceIndex: '1',
    otherFactor: '0',
    snowLoad: '0',
    soilBearingCapacity: '0',
    engineeringRequested: '0',
    stubs: '1',
    // purlinBridgingRequired: '1',
    // overridePurlin: '0',
    // overrideRafter: '0',
    // apexBraceOverride: '0',
    // extraPurlinRows: '0',
    // extraGambrelPurlinRows: '0',
};

export default () => {
    const [building, setBuilding] = useState<Building>(defaultBuilding);

    return { building, setBuilding };
};
