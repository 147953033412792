// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import model_1 from '/Users/jacobdorman/projects/podroof/services/apps/admin/src/pages/Elevation/model';
import model_2 from '/Users/jacobdorman/projects/podroof/services/apps/admin/src/.umi-production/plugin-initialState/@@initialState';

export const models = {
model_1: { namespace: 'Elevation.model', model: model_1 },
model_2: { namespace: '@@initialState', model: model_2 },
} as const
